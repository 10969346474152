import axios from 'axios';
import { Result } from '@/OptimusRoutes/interfaces';
import { ClientScheludedDeactivationDto } from './scheludedDeactivation.dto';

type ScheludedDeactivationServiceType = {
	getClientDisableDate: () => Promise<Result<ClientScheludedDeactivationDto>>;
	saveConfirmDeactivationMessage: (disableDate: Date) => Promise<Result<boolean>>;
};

const getClientDisableDate = async (): Promise<Result<ClientScheludedDeactivationDto>> => {
	const { data } = await axios.get<Result<ClientScheludedDeactivationDto>>(
		`/v2/clients/programmed-deactivation-info`
	);
	return data;
};

const saveConfirmDeactivationMessage = async (disableDate: Date): Promise<Result<boolean>> => {
	const response = await axios.post<Result<boolean>>('/v2/clients/save-deactivation-confirmation', {
		disableDate,
	});
	return response.data;
};

const ScheludedDeactivationService: ScheludedDeactivationServiceType = {
	getClientDisableDate,
	saveConfirmDeactivationMessage,
};

export default ScheludedDeactivationService;
