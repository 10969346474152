import { CLEAN_REDUCER } from '../../constants/actionTypes';
import { LOG_OUT } from '../auth/actionTypes';
import { FETCH_CLIENT_SCHELUDED_DEACTIVATION, UPDATE_SHOW_MODAL_DEACTIVATION } from './actionTypes';

const initState = {
	deactivationInfo: {},
};

const reducer = (state = initState, action) => {
	switch (action.type) {
		case FETCH_CLIENT_SCHELUDED_DEACTIVATION:
			return {
				...state,
				deactivationInfo: action.deactivationInfo,
			};
		case UPDATE_SHOW_MODAL_DEACTIVATION:
			return {
				...state,
				deactivationInfo: {
					...state.deactivationInfo,
					showModal: action.showModal,
				},
			};
		case CLEAN_REDUCER:
			return initState;
		case LOG_OUT:
			return initState;
		default:
			return state;
	}
};

export default reducer;
