import React, { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { translate } from '@smovilgps/optimus-intl';
import { Button, Modal } from 'antd';
import dayjs from 'dayjs';
import unitSystem from '@/features/unitSystems';
import { useAppDispatch, useAppSelector } from '@/OptimusRoutes/hooks/redux.hooks';
import { RootState } from '@/store/configureStore';
import { confirmDeactivationMessage } from './actions';

type Props = {
	open: boolean;
};

const ScheludedDeactivationModal = ({ open }: Props) => {
	const dispatch = useAppDispatch();
	const intl = useIntl();
	const [isButtonDisabled, setIsButtonDisabled] = useState(true);
	const [counter, setCounter] = useState(10);
	const deactivationInfo = useAppSelector((state: RootState) => state.scheludedDeactivation.deactivationInfo);

	const dateFormat = unitSystem.configuration?.date;

	useEffect(() => {
		if (open) {
			setIsButtonDisabled(true);
			setCounter(10);

			const interval = setInterval(() => {
				setCounter((prev) => {
					if (prev === 1) {
						setIsButtonDisabled(false);
						clearInterval(interval);
					}
					return prev - 1;
				});
			}, 1000);

			return () => clearInterval(interval);
		}
	}, [open]);

	return (
		<Modal
			open={open}
			closable={false}
			title={intl.formatMessage(translate('scheduledServiceSuspension'), {
				date: dayjs(deactivationInfo.disableDate).utc().format(dateFormat),
			})}
			footer={
				<Button
					key="ok"
					type="primary"
					disabled={isButtonDisabled}
					onClick={() => {
						dispatch(confirmDeactivationMessage(deactivationInfo.disableDate));
					}}
				>
					{isButtonDisabled ? `${counter} s` : <FormattedMessage {...translate('understood')} />}
				</Button>
			}
		>
			{deactivationInfo.comment}
		</Modal>
	);
};

export default ScheludedDeactivationModal;
