import React, { lazy, Suspense, useEffect, useState } from 'react';
import { IntlProvider } from 'react-intl';
import { useSelector } from 'react-redux';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { ConfigProvider, Spin } from 'antd';
import PropTypes from 'prop-types';
import ProgrammedDeactivationModal from '@/features/scheludedDeactivation/ScheludedDeactivationModal';
import 'chart.js/auto';
import 'chartjs-plugin-datalabels';
import { IS_PRODUCTION, PRIVACY_POLICY_URLS, ROLES } from '../constants/global';
import Overview from '../OptimusRoutes/pages/Overview';

const AlertConfiguration = lazy(() => import('../features/alertConfiguration'));
const AlertConfirm = lazy(() => import('../features/alertConfiguration/AlertConfirm'));
const AWSSales = lazy(() => import('../features/aws-sales/AWSSales'));
const BingSpeed = lazy(() => import('../features/reports/BingSpeed'));
const Branding = lazy(() => import('../features/branding'));
const CanbusTelemetryReport = lazy(() => import('../features/reports/canbusReport'));
const CaeNotification = lazy(() => import('../features/caeNotification/caeNotification'));
const CenosaDeliveryCycle = lazy(() => import('../features/cenosaDeliveryCycles'));
const CenosaDeliveryMonitoring = lazy(() => import('../features/cenosaDeliveryMonitoring'));
const CenosaDeliveryCycleReport = lazy(() => import('../features/reports/CenosaDeliveryCycle'));
const CenosaTrailPage = lazy(() => import('../features/cenosaTrails'));
const ClientAdminsPagePage = lazy(() => import('../features/client-admins'));
const CommandHistory = lazy(() => import('../features/reports/CommandHistory'));
const CommandsPage = lazy(() => import('../features/commandsMassive'));
const ConfirmEmail = lazy(() => import('../features/auth/ConfirmEmail'));
const ControlTPage = lazy(() => import('../features/webhooks/ControlT'));
const CurrentStateReport = lazy(() => import('../features/reports/CurrentState'));
const Dashboard = lazy(() => import('../features/dashboard'));
const Developer = lazy(() => import('../features/developer'));
const DevicePlayer = lazy(() => import('../features/DevicePlayer'));
const DeviceInformationPage = lazy(() => import('../features/deviceInformation/DeviceInformationPage'));
const DevicePage = lazy(() => import('../features/devices'));
const DevicesReport = lazy(() => import('../features/reports/Devices'));
const Distance = lazy(() => import('../features/reports/Distance'));
const Drivers = lazy(() => import('../features/drivers'));
const DrivingReport = lazy(() => import('../features/reports/Driving'));
const DrivinPage = lazy(() => import('../features/webhooks/Driv.in'));
const Empty = lazy(() => import('../features/layouts/empty'));
const EventReport = lazy(() => import('../features/reports/Events'));
const Events = lazy(() => import('../features/events'));
const FuelBillReport = lazy(() => import('../features/reports/FuelControl'));
const FuelControl = lazy(() => import('../features/fuelControl'));
const GasConsumption = lazy(() => import('../features/reports/GasConsumption'));
const Geofences = lazy(() => import('../features/reports/Geofences'));
const GroupPage = lazy(() => import('../features/groups'));
const IdleReport = lazy(() => import('../features/reports/Idle'));
const InactivityReport = lazy(() => import('../features/reports/Inactivity'));
const IntegrationServiceLog = lazy(() => import('../features/webhooks/Common/ServiceLogs'));
const IronTrackingPage = lazy(() => import('../features/webhooks/IronTracking'));
const KronhPage = lazy(() => import('../features/webhooks/Kronh'));
const Login = lazy(() => import('../features/auth/LoginForm'));
const LoginHistory = lazy(() => import('../features/reports/LoginHistory'));
const MaerskPage = lazy(() => import('../features/webhooks/Maersk'));
const Main = lazy(() => import('../features/layouts/main'));
const Maintenance = lazy(() => import('../features/maintenance'));
const MaintenanceLog = lazy(() => import('../features/reports/Maintenance'));
const ManageSubscriptionsPage = lazy(() => import('../features/stripe'));
const MapPage = lazy(() => import('../features/map'));
const MigrateRastreoClient = lazy(() => import('../features/migrateRastreoClient'));
const ModelProfile = lazy(() => import('../features/modelProfile'));
const Models = lazy(() => import('../features/models'));
const Movement = lazy(() => import('../features/reports/Movement'));
const MultipleMap = lazy(() => import('../features/multipleMap'));
const NotFound = lazy(() => import('../features/errors/NotFound'));
const OptimusPage = lazy(() => import('../features/webhooks/Optimus'));
const OsinergminPage = lazy(() => import('../features/webhooks/Osinergmin'));
const ParkingReport = lazy(() => import('../features/reports/Parking'));
const Photos = lazy(() => import('../features/reports/Photos'));
const PointOfInterestReport = lazy(() => import('../features/reports/PointOfInterest'));
const PositionEditor = lazy(() => import('../features/positionEditor'));
const PrivateRoute = lazy(() => import('../features/auth/PrivateRoute'));
const ProtectedRoute = lazy(() => import('../features/auth/ProtectedRoute'));
const PublicMap = lazy(() => import('../features/shareDevicesExternal/PublicMap'));
const RalentiReport = lazy(() => import('../features/reports/Ralenti'));
const RabbitMQTT = lazy(() => import('../features/developer/features/rabbitMQTT'));
const IVSPlayer = lazy(() => import('../features/developer/features/ivs'));
const MapTest = lazy(() => import('../features/developer/features/map'));
const ReplaceCard = lazy(() => import('../features/stripe/ReplaceCard'));
const Reports = lazy(() => import('../features/reports'));
const ResetPassword = lazy(() => import('../features/auth/ResetPassword'));
const ShareApp = lazy(() => import('../features/share-app/ShareApp'));
const RouteDriverPage = lazy(() => import('../OptimusRoutes/pages/Drivers'));
const RouteMonitorPage = lazy(() => import('../features/routeMonitor'));
const Scheduler = lazy(() => import('../OptimusRoutes/pages/TasksScheduler'));
const ScheduleVisitsPage = lazy(() => import('../features/schedule'));
const SecuritasPage = lazy(() => import('../features/webhooks/Securitas'));
const Accessories = lazy(() => import('../features/accessories'));
const ServiceProvidersPage = lazy(() => import('../features/service-providers'));
const Services = lazy(() => import('../features/services'));
const SimCarrierPage = lazy(() => import('../features/sims/SimCarrierPage'));
const SipCopPage = lazy(() => import('../features/webhooks/SipCop'));
const SipCopPolicialPage = lazy(() => import('../features/webhooks/SipCopPolicial'));
const Speeding = lazy(() => import('../features/reports/Speeding'));
const StripePage = lazy(() => import('../features/stripe/CheckoutSession'));
const SutranPage = lazy(() => import('../features/webhooks/Sutran'));
const SyncRastreoOptimus = lazy(() => import('../features/syncRastreoOptimus'));
const TaskPage = lazy(() => import('../OptimusRoutes/pages/Tasks/components/TaskPage'));
const PrivacyPage = lazy(() => import('../features/privacyPage'));
const Temperature = lazy(() => import('../features/reports/Temperature'));
const Trips = lazy(() => import('../features/trips'));
const TrustPilotPage = lazy(() => import('../features/trustPilot'));
const Unauthorized = lazy(() => import('../features/errors/Unauthorized'));
const UnigisPage = lazy(() => import('../features/webhooks/Unigis'));
const UnitsPerClient = lazy(() => import('../features/reports/UnitsPerClient'));
const UnSubscribeFromAlerts = lazy(() => import('../features/unSubscribeFromAlerts'));
const Users = lazy(() => import('../features/users'));
const WalmartPage = lazy(() => import('../features/webhooks/Walmart'));
const Webhooks = lazy(() => import('../features/webhooks'));
const StarTrackPage = lazy(() => import('../features/webhooks/StarTrack'));
const RouteSubscriptionPage = lazy(() => import('../features/stripeRoutes/pages/CheckoutSession'));
const RouteForm = lazy(() => import('../features/stripeRoutes/pages/CheckoutSession/components/SubscriptionForm'));
const RouteCheckoutResult = lazy(() =>
	import('../features/stripeRoutes/pages/CheckoutSession/components/SubscriptionResult')
);
const AlephPage = lazy(() => import('../features/webhooks/Aleph'));
const TasksRoutesReport = lazy(() => import('../features/reports/TaskRoutes'));
const FrequentPointsReport = lazy(() => import('../features/reports/FrequentPoints'));

const BigRentalsPage = lazy(() => import('../features/webhooks/BigRentals'));

const Root = ({ locale, lang }) => {
	const brands = useSelector((state) => state.branding.brandings);
	const user = useSelector((state) => state.auth.user);
	const deactivationInfo = useSelector((state) => state.scheludedDeactivation.deactivationInfo);
	const [isOptimusTracker, setIsOptimusTracker] = useState(false);

	useEffect(() => {
		let brand = brands.find((brand) => brand.domain === window.location.host);
		if (brand) {
			document.title = brand.name;
		}
	}, [brands]);

	useEffect(() => {
		if (user?.id && process.env.REACT_APP_OPTIMUS_TRACKER) {
			const validIds = process.env.REACT_APP_OPTIMUS_TRACKER.split(',').map((id) => Number(id));
			setIsOptimusTracker(validIds.includes(user.id));
		}
	}, [user]);

	const onError = (error) => {
		if (IS_PRODUCTION) {
		} else {
			/* eslint-disable-next-line */
			console.error('Trackjs', { error });
		}
	};

	const MigrateRastreoClientPage = isOptimusTracker && (
		<Route
			path="rastreo-clients"
			element={
				<ProtectedRoute authorizedRoles={[ROLES.OPTIMUS_MANAGEMENT]}>
					<MigrateRastreoClient />
				</ProtectedRoute>
			}
		/>
	);

	return (
		<ConfigProvider locale={locale[lang].language}>
			<IntlProvider locale={locale[lang].intl} messages={locale[lang].messages} onError={onError}>
				{deactivationInfo && <ProgrammedDeactivationModal open={deactivationInfo.showModal} />}
				<Router>
					<Suspense
						fallback={
							<Spin tip="Loading" size="large">
								<div style={{ width: '100vw', height: '100vh' }}></div>
							</Spin>
						}
					>
						<Routes>
							<Route
								path="/"
								element={
									<PrivateRoute>
										<Main />
									</PrivateRoute>
								}
							>
								<Route
									index
									element={
										<ProtectedRoute index>
											<Dashboard />
										</ProtectedRoute>
									}
								/>

								<Route
									path="dashboard"
									element={
										<ProtectedRoute>
											<Dashboard />
										</ProtectedRoute>
									}
								/>
								<Route
									path="map"
									element={
										<ProtectedRoute authorizedRoles={[ROLES.MAP, ROLES.ROUTE_MONITOR]}>
											<MapPage />
										</ProtectedRoute>
									}
								/>
								<Route
									path="multiple-map"
									element={
										<ProtectedRoute authorizedRoles={[ROLES.MAP]}>
											<MultipleMap />
										</ProtectedRoute>
									}
								/>

								<Route
									path="DevicePlayer"
									element={
										<ProtectedRoute authorizedRoles={[ROLES.MAP]}>
											<DevicePlayer />
										</ProtectedRoute>
									}
								/>

								<Route
									path="route-monitor"
									element={
										<ProtectedRoute authorizedRoles={[ROLES.MAP]}>
											<RouteMonitorPage />
										</ProtectedRoute>
									}
								/>
								<Route path="reports">
									<Route
										index
										element={
											<ProtectedRoute
												authorizedRoles={[ROLES.REPORTS, ROLES.MASTER, ROLES.ACCOUNT_MANAGER]}
											>
												<Reports />
											</ProtectedRoute>
										}
									/>
									<Route path="driving" element={<DrivingReport />} />
									<Route path="idle" element={<IdleReport />} />
									<Route path="parking" element={<ParkingReport />} />
									<Route path="distance" element={<Distance />} />
									<Route path="speeding" element={<Speeding />} />
									<Route path="positionLog" element={<Movement />} />
									<Route path="unit-history" element={<Movement />} />
									<Route
										path="canbus"
										element={
											<ProtectedRoute authorizedRoles={[ROLES.CANBUS_TELEMETRY_REPORT]}>
												<CanbusTelemetryReport />
											</ProtectedRoute>
										}
									/>
									<Route
										path="maintenanceLog"
										element={
											<ProtectedRoute authorizedRoles={[ROLES.MAINTENANCE_REPORT]}>
												<MaintenanceLog />
											</ProtectedRoute>
										}
									/>
									<Route
										path="fuelConsumption"
										element={
											<ProtectedRoute authorizedRoles={[ROLES.FUEL_REPORT]}>
												<GasConsumption />
											</ProtectedRoute>
										}
									/>
									<Route
										path="pointOfInterest"
										element={
											<ProtectedRoute authorizedRoles={[ROLES.POI_REPORT]}>
												<PointOfInterestReport />
											</ProtectedRoute>
										}
									/>
									<Route
										path="commandHistory"
										element={
											<ProtectedRoute authorizedRoles={[ROLES.COMMAND_REPORT]}>
												<CommandHistory />
											</ProtectedRoute>
										}
									/>
									<Route
										path="loginHistory"
										element={
											<ProtectedRoute authorizedRoles={[ROLES.LOGIN_HISTORY_REPORT]}>
												<LoginHistory />
											</ProtectedRoute>
										}
									/>
									<Route
										path="unitsPerClient"
										element={
											<ProtectedRoute authorizedRoles={[ROLES.SERVICE_PROVIDER]}>
												<UnitsPerClient />
											</ProtectedRoute>
										}
									/>
									<Route
										path="geofences"
										element={
											<ProtectedRoute authorizedRoles={[ROLES.GEOFENCE_REPORT]}>
												<Geofences />
											</ProtectedRoute>
										}
									/>
									<Route
										path="photos"
										element={
											<ProtectedRoute authorizedRoles={[ROLES.CAMERA_REPORT]}>
												<Photos />
											</ProtectedRoute>
										}
									/>
									<Route path="events" element={<EventReport />} />
									<Route path="currentState" element={<CurrentStateReport />} />
									<Route
										path="temperature"
										element={
											<ProtectedRoute authorizedRoles={[ROLES.TEMPERATURE_REPORT]}>
												<Temperature />
											</ProtectedRoute>
										}
									/>
									<Route
										path="fuel-control"
										element={
											<ProtectedRoute authorizedRoles={[ROLES.FUEL_CONTROL]}>
												<FuelBillReport />
											</ProtectedRoute>
										}
									/>
									<Route
										path="devices"
										element={
											<ProtectedRoute authorizedRoles={[ROLES.MASTER, ROLES.ACCOUNT_MANAGER]}>
												<DevicesReport />
											</ProtectedRoute>
										}
									/>
									<Route
										path="bing-speed"
										element={
											<ProtectedRoute authorizedRoles={[ROLES.BING_SPEED]}>
												<BingSpeed />
											</ProtectedRoute>
										}
									/>
									<Route
										path="inactivity"
										element={
											<ProtectedRoute authorizedRoles={[ROLES.SERVICE_PROVIDER]}>
												<InactivityReport />
											</ProtectedRoute>
										}
									/>
									<Route
										path="ralenti"
										element={
											<ProtectedRoute authorizedRoles={[ROLES.RALENTI_REPORT]}>
												<RalentiReport />
											</ProtectedRoute>
										}
									/>
									<Route
										path="task-routes"
										element={
											<ProtectedRoute authorizedRoles={[ROLES.TASK_ROUTE_REPORT]}>
												<TasksRoutesReport />
											</ProtectedRoute>
										}
									/>
									<Route
										path="frequent-points"
										element={
											<ProtectedRoute authorizedRoles={[ROLES.FREQUENT_POINTS_REPORT]}>
												<FrequentPointsReport />
											</ProtectedRoute>
										}
									/>
								</Route>
								<Route path="optimus-routes/task-planner">
									<Route
										index
										element={
											<ProtectedRoute authorizedRoles={[ROLES.TRACKING_ROUTING]}>
												<Scheduler />
											</ProtectedRoute>
										}
									/>
								</Route>
								<Route path="tracked-phones">
									<Route
										index
										element={
											<ProtectedRoute authorizedRoles={[ROLES.TRACKING_ROUTING, ROLES.TRACKING]}>
												<RouteDriverPage />
											</ProtectedRoute>
										}
									/>
								</Route>
								<Route path="optimus-routes">
									<Route
										index
										element={
											<ProtectedRoute authorizedRoles={[ROLES.TRACKING_ROUTING, ROLES.TRACKING]}>
												<Overview />
											</ProtectedRoute>
										}
									/>
								</Route>
								<Route path="optimus-routes/tasks-management">
									<Route
										index
										element={
											<ProtectedRoute authorizedRoles={[ROLES.TRACKING_ROUTING]}>
												{/* <TasksCreate /> */}
												<TaskPage />
											</ProtectedRoute>
										}
									/>
								</Route>
								<Route
									path="sukarne-trips"
									element={
										<ProtectedRoute authorizedRoles={[ROLES.SUKARNE]}>
											<Trips />
										</ProtectedRoute>
									}
								/>

								<Route
									path="users"
									element={
										<ProtectedRoute
											authorizedRoles={[
												ROLES.SERVICE_PROVIDER,
												ROLES.MANAGE_USERS,
												ROLES.CLIENT_ADMIN,
											]}
										>
											<Users />
										</ProtectedRoute>
									}
								/>
								<Route
									path="accessories"
									element={
										<ProtectedRoute authorizedRoles={[ROLES.MANAGE_SENSORS]}>
											<Accessories />
										</ProtectedRoute>
									}
								/>
								<Route
									path="devices"
									element={
										<ProtectedRoute authorizedRoles={[ROLES.SERVICE_PROVIDER, ROLES.SUPPORT]}>
											<DevicePage />
										</ProtectedRoute>
									}
								/>
								<Route
									path="devices/information"
									element={
										<ProtectedRoute authorizedRoles={[ROLES.MONITOR_DEVICE_INFO]}>
											<DeviceInformationPage />
										</ProtectedRoute>
									}
								/>
								<Route
									path="sims"
									element={
										<ProtectedRoute authorizedRoles={[ROLES.SERVICE_PROVIDER]}>
											<SimCarrierPage />
										</ProtectedRoute>
									}
								/>
								<Route
									path="service-providers"
									element={
										<ProtectedRoute authorizedRoles={[ROLES.MASTER, ROLES.ACCOUNT_MANAGER]}>
											<ServiceProvidersPage />
										</ProtectedRoute>
									}
								/>
								<Route
									path="client-admins"
									element={
										<ProtectedRoute authorizedRoles={[ROLES.SERVICE_PROVIDER, ROLES.SUPPORT]}>
											<ClientAdminsPagePage />
										</ProtectedRoute>
									}
								/>
								<Route
									path="groups"
									element={
										<ProtectedRoute authorizedRoles={[ROLES.GROUP]}>
											<GroupPage />
										</ProtectedRoute>
									}
								/>
								<Route
									path="maintenance"
									element={
										<ProtectedRoute authorizedRoles={[ROLES.MAINTENANCE]}>
											<Maintenance />
										</ProtectedRoute>
									}
								/>
								<Route
									path="events"
									element={
										<ProtectedRoute authorizedRoles={[ROLES.MASTER]}>
											<Events />
										</ProtectedRoute>
									}
								/>
								<Route
									path="notification"
									element={
										<ProtectedRoute authorizedRoles={[ROLES.NOTIFICATION_ADMIN]}>
											<AlertConfiguration />
										</ProtectedRoute>
									}
								/>
								<Route
									path="alerts"
									element={
										<ProtectedRoute authorizedRoles={[ROLES.NOTIFICATION_ADMIN]}>
											<AlertConfiguration />
										</ProtectedRoute>
									}
								/>
								<Route
									path="services"
									element={
										<ProtectedRoute authorizedRoles={[ROLES.SERVICE_PROVIDER]}>
											<Services />
										</ProtectedRoute>
									}
								/>
								<Route
									path="position-editor"
									element={
										<ProtectedRoute authorizedRoles={[ROLES.SERVICE_PROVIDER]}>
											<PositionEditor />
										</ProtectedRoute>
									}
								/>
								<Route path="models">
									<Route
										index
										element={
											<ProtectedRoute authorizedRoles={[ROLES.MASTER]}>
												<Models />
											</ProtectedRoute>
										}
									/>
									<Route
										path="profile"
										element={
											<ProtectedRoute authorizedRoles={[ROLES.MASTER]}>
												<ModelProfile />
											</ProtectedRoute>
										}
									/>
								</Route>
								<Route
									path="aws-sales"
									element={
										<ProtectedRoute authorizedRoles={[ROLES.MASTER]}>
											<AWSSales />
										</ProtectedRoute>
									}
								/>
								<Route
									path="branding"
									element={
										<ProtectedRoute authorizedRoles={[ROLES.MASTER, ROLES.ACCOUNT_MANAGER]}>
											<Branding />
										</ProtectedRoute>
									}
								/>
								<Route
									path="fuel-control"
									element={
										<ProtectedRoute authorizedRoles={[ROLES.FUEL_CONTROL]}>
											<FuelControl />
										</ProtectedRoute>
									}
								/>
								<Route
									path="drivers"
									element={
										<ProtectedRoute authorizedRoles={[ROLES.MANAGE_DRIVERS]}>
											<Drivers />
										</ProtectedRoute>
									}
								/>

								<Route
									path="schedule-visits"
									element={
										<ProtectedRoute authorizedRoles={[ROLES.ROUTE_MONITOR]}>
											<ScheduleVisitsPage />
										</ProtectedRoute>
									}
								/>
								<Route
									path="sync-rastreo-optimus"
									element={
										<ProtectedRoute authorizedRoles={[ROLES.MASTER, ROLES.ACCOUNT_MANAGER]}>
											<SyncRastreoOptimus />
										</ProtectedRoute>
									}
								/>
								<Route path="integrations">
									<Route
										index
										element={
											<ProtectedRoute authorizedRoles={[ROLES.INTEGRATIONS]}>
												<Webhooks />
											</ProtectedRoute>
										}
									/>
									<Route path="walmart-service">
										<Route
											index
											element={
												<ProtectedRoute authorizedRoles={[ROLES.INTEGRATIONS]}>
													<WalmartPage />
												</ProtectedRoute>
											}
										/>
										<Route
											path="logs"
											element={
												<ProtectedRoute authorizedRoles={[ROLES.INTEGRATIONS]}>
													<IntegrationServiceLog />
												</ProtectedRoute>
											}
										/>
									</Route>
									<Route path="sutran">
										<Route
											index
											element={
												<ProtectedRoute authorizedRoles={[ROLES.INTEGRATIONS]}>
													<SutranPage />
												</ProtectedRoute>
											}
										/>
										<Route
											path="logs"
											element={
												<ProtectedRoute authorizedRoles={[ROLES.INTEGRATIONS]}>
													<IntegrationServiceLog />
												</ProtectedRoute>
											}
										/>
									</Route>
									<Route path="controlt">
										<Route
											index
											element={
												<ProtectedRoute authorizedRoles={[ROLES.INTEGRATIONS]}>
													<ControlTPage />
												</ProtectedRoute>
											}
										/>
										<Route
											path="logs"
											element={
												<ProtectedRoute authorizedRoles={[ROLES.INTEGRATIONS]}>
													<IntegrationServiceLog />
												</ProtectedRoute>
											}
										/>
									</Route>
									<Route path="securitas">
										<Route
											index
											element={
												<ProtectedRoute authorizedRoles={[ROLES.INTEGRATIONS]}>
													<SecuritasPage />
												</ProtectedRoute>
											}
										/>
										<Route
											path="logs"
											element={
												<ProtectedRoute authorizedRoles={[ROLES.INTEGRATIONS]}>
													<IntegrationServiceLog />
												</ProtectedRoute>
											}
										/>
									</Route>
									<Route path="osinergmin">
										<Route
											index
											element={
												<ProtectedRoute authorizedRoles={[ROLES.INTEGRATIONS]}>
													<OsinergminPage />
												</ProtectedRoute>
											}
										/>
										<Route
											path="logs"
											element={
												<ProtectedRoute authorizedRoles={[ROLES.INTEGRATIONS]}>
													<IntegrationServiceLog />
												</ProtectedRoute>
											}
										/>
									</Route>
									<Route path="sipcop">
										<Route
											index
											element={
												<ProtectedRoute authorizedRoles={[ROLES.INTEGRATIONS]}>
													<SipCopPage />
												</ProtectedRoute>
											}
										/>
										<Route
											path="logs"
											element={
												<ProtectedRoute authorizedRoles={[ROLES.INTEGRATIONS]}>
													<IntegrationServiceLog />
												</ProtectedRoute>
											}
										/>
									</Route>
									<Route path="unigis">
										<Route
											index
											element={
												<ProtectedRoute authorizedRoles={[ROLES.INTEGRATIONS]}>
													<UnigisPage />
												</ProtectedRoute>
											}
										/>
										<Route
											path="logs"
											element={
												<ProtectedRoute authorizedRoles={[ROLES.INTEGRATIONS]}>
													<IntegrationServiceLog />
												</ProtectedRoute>
											}
										/>
									</Route>
									<Route path="optimus">
										<Route
											index
											element={
												<ProtectedRoute authorizedRoles={[ROLES.INTEGRATIONS]}>
													<OptimusPage />
												</ProtectedRoute>
											}
										/>
										<Route
											path="logs"
											element={
												<ProtectedRoute authorizedRoles={[ROLES.INTEGRATIONS]}>
													<IntegrationServiceLog />
												</ProtectedRoute>
											}
										/>
									</Route>
									<Route path="maersk">
										<Route
											index
											element={
												<ProtectedRoute authorizedRoles={[ROLES.INTEGRATIONS]}>
													<MaerskPage />
												</ProtectedRoute>
											}
										/>
										<Route
											path="logs"
											element={
												<ProtectedRoute authorizedRoles={[ROLES.INTEGRATIONS]}>
													<IntegrationServiceLog />
												</ProtectedRoute>
											}
										/>
									</Route>
									<Route path="kronh">
										<Route
											index
											element={
												<ProtectedRoute authorizedRoles={[ROLES.INTEGRATIONS]}>
													<KronhPage />
												</ProtectedRoute>
											}
										/>
										<Route
											path="logs"
											element={
												<ProtectedRoute authorizedRoles={[ROLES.INTEGRATIONS]}>
													<IntegrationServiceLog />
												</ProtectedRoute>
											}
										/>
									</Route>
									<Route path="drivin">
										<Route
											index
											element={
												<ProtectedRoute authorizedRoles={[ROLES.INTEGRATIONS]}>
													<DrivinPage />
												</ProtectedRoute>
											}
										/>
										<Route
											path="logs"
											element={
												<ProtectedRoute authorizedRoles={[ROLES.INTEGRATIONS]}>
													<IntegrationServiceLog />
												</ProtectedRoute>
											}
										/>
									</Route>
									<Route path="iron-tracking">
										<Route
											index
											element={
												<ProtectedRoute authorizedRoles={[ROLES.INTEGRATIONS]}>
													<IronTrackingPage />
												</ProtectedRoute>
											}
										/>
										<Route
											path="logs"
											element={
												<ProtectedRoute authorizedRoles={[ROLES.INTEGRATIONS]}>
													<IntegrationServiceLog />
												</ProtectedRoute>
											}
										/>
									</Route>
									<Route path="starTrack">
										<Route
											index
											element={
												<ProtectedRoute authorizedRoles={[ROLES.INTEGRATIONS]}>
													<StarTrackPage />
												</ProtectedRoute>
											}
										/>
										<Route
											path="logs"
											element={
												<ProtectedRoute authorizedRoles={[ROLES.INTEGRATIONS]}>
													<IntegrationServiceLog />
												</ProtectedRoute>
											}
										/>
									</Route>
									<Route path="sipcopPolicial">
										<Route
											index
											element={
												<ProtectedRoute authorizedRoles={[ROLES.INTEGRATIONS]}>
													<SipCopPolicialPage />
												</ProtectedRoute>
											}
										/>
										<Route
											path="logs"
											element={
												<ProtectedRoute authorizedRoles={[ROLES.INTEGRATIONS]}>
													<IntegrationServiceLog />
												</ProtectedRoute>
											}
										/>
									</Route>
									<Route path="aleph">
										<Route
											index
											element={
												<ProtectedRoute authorizedRoles={[ROLES.INTEGRATIONS]}>
													<AlephPage />
												</ProtectedRoute>
											}
										/>
										<Route
											path="logs"
											element={
												<ProtectedRoute authorizedRoles={[ROLES.INTEGRATIONS]}>
													<IntegrationServiceLog />
												</ProtectedRoute>
											}
										/>
									</Route>
									<Route path="bigRentals">
										<Route
											index
											element={
												<ProtectedRoute authorizedRoles={[ROLES.INTEGRATIONS]}>
													<BigRentalsPage />
												</ProtectedRoute>
											}
										/>
										<Route
											path="logs"
											element={
												<ProtectedRoute authorizedRoles={[ROLES.INTEGRATIONS]}>
													<IntegrationServiceLog />
												</ProtectedRoute>
											}
										/>
									</Route>
								</Route>
								<Route
									path="send-commands"
									element={
										<ProtectedRoute
											authorizedRoles={[
												ROLES.COMMAND_MASSIVE,
												ROLES.COMMAND_AUTOMATIC,
												ROLES.COMMAND_PROGRAMMED,
												ROLES.SERVICE_PROVIDER,
											]}
										>
											<CommandsPage />
										</ProtectedRoute>
									}
								/>
								<Route
									path="trust-pilot"
									element={
										<ProtectedRoute authorizedRoles={[ROLES.TRUST_PILOT_MANAGEMENT]}>
											<TrustPilotPage />
										</ProtectedRoute>
									}
								/>
								<Route
									path="stripe/manage-subscriptions"
									element={
										<ProtectedRoute authorizedRoles={[ROLES.SUBSCRIPTION_MANAGEMENT]}>
											<ManageSubscriptionsPage />
										</ProtectedRoute>
									}
								/>
								<Route
									path="cenosa-trails"
									element={
										<ProtectedRoute authorizedRoles={[ROLES.CYCLE_MONITORING]}>
											<CenosaTrailPage />
										</ProtectedRoute>
									}
								/>
								<Route
									path="cenosa-delivery-cycle"
									element={
										<ProtectedRoute authorizedRoles={[ROLES.CYCLE_MONITORING]}>
											<CenosaDeliveryCycle />
										</ProtectedRoute>
									}
								/>
								<Route
									path="cenosa-delivery-monitoring"
									element={
										<ProtectedRoute authorizedRoles={[ROLES.CYCLE_MONITORING]}>
											<CenosaDeliveryMonitoring />
										</ProtectedRoute>
									}
								/>
								<Route
									path="cenosa-delivery-cycle-report"
									element={
										<ProtectedRoute authorizedRoles={[ROLES.CYCLE_MONITORING]}>
											<CenosaDeliveryCycleReport />
										</ProtectedRoute>
									}
								/>
								<Route path="developer">
									<Route
										index
										element={
											<ProtectedRoute authorizedRoles={[ROLES.DEVELOPER]}>
												<Developer />
											</ProtectedRoute>
										}
									/>
									<Route
										path="rabbit-mqtt"
										element={
											<ProtectedRoute authorizedRoles={[ROLES.DEVELOPER]}>
												<RabbitMQTT />
											</ProtectedRoute>
										}
									/>
									<Route
										path="ivs-player"
										element={
											<ProtectedRoute authorizedRoles={[ROLES.DEVELOPER]}>
												<IVSPlayer />
											</ProtectedRoute>
										}
									/>
									<Route
										path="map"
										element={
											<ProtectedRoute authorizedRoles={[ROLES.DEVELOPER]}>
												<MapTest />
											</ProtectedRoute>
										}
									/>
								</Route>
								{MigrateRastreoClientPage}
							</Route>
							<Route element={<Empty />}>
								<Route path="guest/map" element={<PublicMap />} />,
								<Route path="cae/:trackingHistoryId" element={<CaeNotification />} />,
								<Route path="unSubscribeFromAlerts/:emailId" element={<UnSubscribeFromAlerts />} />,
								<Route path="confirm" element={<ConfirmEmail />} />,
								<Route path="emailConfirm" element={<AlertConfirm />} />,
								<Route path="smsConfirm" element={<AlertConfirm />} />,
								<Route path="login" element={<Login />} />
								<Route
									path="privacy-policy/optimus-routes"
									element={<PrivacyPage url={PRIVACY_POLICY_URLS.optimusRoutes} />}
								/>
								<Route
									path="privacy-policy/optimus-ca"
									element={<PrivacyPage url={PRIVACY_POLICY_URLS.optimus2} />}
								/>
								<Route
									path="privacy-policy/magno-track"
									element={<PrivacyPage url={PRIVACY_POLICY_URLS.magnoTrack} />}
								/>
								<Route
									path="privacy-policy/negev-tracking"
									element={<PrivacyPage url={PRIVACY_POLICY_URLS.negev} />}
								/>
								<Route path="reset-password" element={<ResetPassword />} />,
								<Route path="apps/optimus-routes" element={<ShareApp />} />,
								<Route
									path="change-password"
									element={
										<ProtectedRoute>
											<ResetPassword />
										</ProtectedRoute>
									}
								/>
								<Route path="stripe">
									<Route path="subscription" element={<StripePage />} />
									<Route path="replace-card-details" element={<ReplaceCard />} />
									<Route path="routes/subscription" element={<RouteSubscriptionPage />} />
									<Route path="routes/form" element={<RouteForm />} />
									<Route path="routes/checkout" element={<RouteCheckoutResult />} />
								</Route>
								<Route path="unauthorized" element={<Unauthorized />} />
								<Route path="*" element={<NotFound />} />
							</Route>
						</Routes>
					</Suspense>
				</Router>
			</IntlProvider>
		</ConfigProvider>
	);
};

Root.propTypes = {
	locale: PropTypes.object.isRequired,
	lang: PropTypes.string.isRequired,
};

export default Root;
