import { messages, translate } from '@smovilgps/optimus-intl';
import { message } from 'antd';
import { parallelLimit } from 'async';
import _ from 'lodash';
import { all, create } from 'mathjs';
import MobileDetect from 'mobile-detect';
import Moment from 'moment';
import { extendMoment } from 'moment-range';
import compile from 'string-template/compile';
import codeGenerator from 'voucher-code-generator';
import { scheduleTemplate } from '@/features/alertConfiguration/components/AuthorizedScheduleConfig';
import { DIGITAL_INPUTS, EVENT_ENUM, MIN_SPEED_DEFAULT, REGEX, SIREN } from '../constants/global';
import unitSystem from '../features/unitSystems';
import icons from '../lib/icon';

const moment = extendMoment(Moment);
const math = create(all);
const parser = new math.parser();
const LITER = 3.78541;

export const isEmail = (email) => {
	let re = REGEX.email;
	return re.test(email.toLowerCase().trim());
};

export const asyncActionType = (PREFIX, ACTION) => {
	switch (PREFIX) {
		case 'GET':
		case 'DELETE':
			return {
				PENDING: `${PREFIX}_${!_.isEmpty(ACTION) ? `${ACTION}_` : ''}PENDING`,
				SUCCESS: `${PREFIX}_${!_.isEmpty(ACTION) ? `${ACTION}_` : ''}SUCCESS`,
				FAILURE: `${PREFIX}_${!_.isEmpty(ACTION) ? `${ACTION}_` : ''}FAILURE`,
				PREFIX: `${PREFIX}${!_.isEmpty(ACTION) ? `_${ACTION}_` : ''}`,
			};
		case 'ADD':
		case 'EDIT':
			return {
				PENDING: `${PREFIX}_${!_.isEmpty(ACTION) ? `${ACTION}_` : ''}PENDING`,
				SUCCESS: `${PREFIX}_${!_.isEmpty(ACTION) ? `${ACTION}_` : ''}SUCCESS`,
				FAILURE: `${PREFIX}_${!_.isEmpty(ACTION) ? `${ACTION}_` : ''}FAILURE`,
				START: `START_${PREFIX}_${ACTION}`,
				CANCEL: `CANCEL_${PREFIX}_${ACTION}`,
				PREFIX: `${PREFIX}${!_.isEmpty(ACTION) ? `_${ACTION}` : ''}`, // EDIT_ACTION
			};
		case 'SELECT':
			return {
				SELECT: `${PREFIX}_${ACTION}`,
			};
		case 'SEARCH':
			return {
				SEARCH: `${PREFIX}_${ACTION}`,
			};
		default:
			return {
				PENDING: `${PREFIX}_PENDING`,
				SUCCESS: `${PREFIX}_SUCCESS`,
				FAILURE: `${PREFIX}_FAILURE`,
				PREFIX,
			};
	}
};

export async function passwordVerification(rule, password) {
	const { form } = this.props;
	if (password && password !== form.getFieldValue('user.password')) throw new Error('Password does not match!');
}

export async function emailVerification(verifyEmailExistance, rule, email) {
	if (_.isEmpty(email)) return;
	if (this.props.data.hasOwnProperty(rule.field) && email === this.props.data.email) return;
	const exists = await verifyEmailExistance(email);
	if (exists) throw new Error('emailAlreadyExist');
}

export async function usernameVerification(verifyUsernameExistance, rule, username) {
	if (_.isEmpty(username)) return;
	if (this.props.data.hasOwnProperty(rule.field) && username === this.props.data.user.username) return;
	const exists = await verifyUsernameExistance(username);
	if (exists) throw new Error('usernameAlreadyExist');
}

export const isPhone = (phone) => {
	let re = REGEX.phone;
	return re.test(phone);
};

export const isAreaCode = (areaCodeStr) => {
	let re = REGEX.areaCode;
	return re.test(areaCodeStr);
};

export const isUsaPhone = (phone) => {
	let re = REGEX.usaPhone;
	return re.test(phone);
};

export const notify = (msg, options = {}) => {
	let compiler = compile(msg);
	message.success(compiler(options), 5);
};

export const isLocalhost = Boolean(
	window.location.hostname === 'localhost' ||
		// [::1] is the IPv6 localhost address.
		window.location.hostname === '[::1]' ||
		// 127.0.0.0/8 are considered localhost for IPv4.
		window.location.hostname.match(/^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/)
);

export const elementExist = (arr, key, value) => {
	if (!value) return false;
	for (let i = 0; i < arr.length; i++) {
		let element = arr[i];
		if (element[key] === value.id) return true;
	}
	return false;
};

export const getRowObject = (id, arr) => {
	let item = arr.filter((element) => element.id === id);
	return item.length > 0 ? item[0] : {};
};

export const errorFields = (record, msg) => {
	let scapeRegex = new RegExp('([[|\\|^|$|.|||?|*|+|(|)|{|}|])', 'g');
	let theKeys = Object.keys(record);
	for (let i = 0; i < theKeys.length; i++) {
		let element = theKeys[i];
		let reg = new RegExp('^.*\\(' + record[element].replace(scapeRegex, '\\$1') + '\\).*$');
		if (reg.test(msg)) {
			return element;
		}
	}
};

export const isMobile = () => {
	let md = new MobileDetect(window.navigator.userAgent);
	let mobile = md.phone();

	return !!mobile;
};

export const isiOS = () => {
	const md = new MobileDetect(window.navigator.userAgent);
	const OS = md.os();

	return OS === 'iOS';
};

export const isTablet = () => {
	let md = new MobileDetect(window.navigator.userAgent);
	let mobile = md.mobile();

	return !!mobile;
};

export const splitName = (name) => {
	let output = name.trim().split(' ')[0];
	if (output.length > 10) return `${output.substring(0, 7)}...`;
	return output;
};

export const s3UploadLink = (token, folder, fileName = '', id = -1) => {
	const url = `/api/S3Buckets/upload/${folder}/${fileName}/${id}?access_token=${token}`;
	return url;
};

export const getDowmnloadTemplateUrl = ({ folderPath = '', name, extension = 'xlsx' }) => {
	const origin = isLocalhost ? 'https://dev.optimushn.com' : window.location.origin;
	return `${origin}/downloads/templates/${folderPath}/${name}.${extension}`;
};

export const getDowmnloadLegendUrl = ({ folderPath = '', name }) => {
	const origin = isLocalhost ? 'https://dev.optimushn.com' : window.location.origin;
	return `${origin}/downloads/templates/${folderPath}/${name}.txt`;
};

export const amazonImageUrl = ({ folder = '', name, extension = 'png', enableCache = false }) => {
	const origin = window.location.origin;
	const folderPath = folder.length ? `/${folder}` : folder;
	let generatedCode = '';
	if (!enableCache) {
		const code = codeGenerator.generate({
			length: 6,
			count: 1,
			charset: codeGenerator.charset('numbers'),
		})[0];
		generatedCode = `?v${code}`;
	}

	if (isLocalhost) {
		return `https://dev.optimushn.com/images${folderPath}/${name}.${extension}${generatedCode}`;
	}
	return `${origin}/images${folderPath}/${name}.${extension}${generatedCode}`;
};

export const ACL4Component = (roles, MyComponent, permitedRoles) => {
	if (!permitedRoles) return MyComponent;
	let permited = false;
	for (let i = 0; i < permitedRoles.length; i++) {
		if (roles.indexOf(permitedRoles[i]) >= 0) permited = true;
	}

	return permited && MyComponent;
};

const pause = (duration) => new Promise((res) => setTimeout(res, duration));

export const retryCall = (retries, fn, delay = 500) => {
	try {
		fn();
	} catch (error) {
		retries > 1 ? pause(delay).then(() => retryCall(retries - 1, fn, delay * 2)) : Promise.reject(error);
	}
};

export const AlertConstructor = (data, messages) => {
	let res = {
		type: 'alert',
		read: data.alert.seen,
		original: data,
		avatar: 'https://gw.alipayobjects.com/zos/rmsportal/kISTdvpyTAhtGxpovNWd.png',
		datetime: moment.utc(data.alert.utcDate).utcOffset(data.device.utcOffsetMinutes).fromNow(),
	};

	res.title = GetEventTitle(data.alert.event, messages);
	res.description = GetEventDescription(data.device, data.alert.event, messages);

	return res;
};

export const GetEventTitle = (event, messages) => {
	if (`event${event.id}` in messages) {
		if (DIGITAL_INPUTS.includes(event.id)) {
			let eventData = event.data ? JSON.parse(event.data) : {};
			return eventData.description || messages[`event${event.id}`];
		} else if (event.id === EVENT_ENUM.PHOTO_REPORT) {
			let eventData = JSON.parse(event.data);

			if (eventData.status === 2) {
				return messages['fotoProcessedTitle'];
			}

			return messages['fotoInProcessTitle'];
		} else {
			switch (event.id) {
				case EVENT_ENUM.TEMPERATURE_ALERT: {
					let eventData = event.data ? JSON.parse(event.data) : {};
					if (typeof eventData.isMin !== 'undefined')
						return messages[`event${event.id}${eventData.isMin ? 'min' : 'max'}`];
					return messages[`event${event.id}`];
				}
				case EVENT_ENUM.OUT_OF_SCHEDULE:
					return messages['unAuthorizeSchedule'];
				default:
					return messages[`event${event.id}`];
			}
		}
	} else {
		return messages[`unknownEvent`];
	}
};

export const GetEventDescription = (device, event, messages, isShortMessage = false, tempWidget = false) => {
	var eventKey = isShortMessage ? `event${event.id}` : `eventsDescription${event.id}`;
	if (eventKey in messages) {
		let translation = messages[eventKey];
		let compiler = compile(translation);
		// TO-DO
		/*
			Extraer esta funcionalidad ya que no tiene razon de existir aqui
		*/
		if (event.id === EVENT_ENUM.TEMPERATURE_ALERT) {
			if (!event.data) return messages[`event${event.id}`];
			let eventData = JSON.parse(event.data);
			if (tempWidget) {
				return eventData;
			} else {
				return compile(messages[`eventsDescription${event.id}${eventData.isMin ? 'min' : 'max'}`])({
					temperature: unitSystem.temperature.toString(eventData.temperature, { decimal: 0 }),
					temperatureLimit: unitSystem.temperature.toString(eventData.temperatureLimit, {
						decimal: 0,
					}),
					description: eventData.description,
					device: device ? device.description : '',
				});
			}
		}
		if (
			event.id === EVENT_ENUM.GEOFENCE_IN ||
			event.id === EVENT_ENUM.GEOFENCE_OUT ||
			event.id === EVENT_ENUM.DWELL
		) {
			let eventData = JSON.parse(event.data);
			let geofenceDescription = compiler({
				description: device.description,
				name: eventData.name,
			});
			return geofenceDescription;
		} else if (event.id === EVENT_ENUM.PHOTO_REPORT) {
			let eventData = JSON.parse(event.data);

			if (eventData.status === 2) {
				translation = messages['fotoProcessed'];
			} else {
				translation = messages['fotoInProcess'];
			}

			compiler = compile(translation);
			return compiler({
				description: device ? device.description : '',
			});
		} else if (DIGITAL_INPUTS.includes(event.id)) {
			let eventData = event.data ? JSON.parse(event.data) : {};
			return compiler({
				inputDescription: eventData.description || messages[`event${event.id}`],
				description: device ? device.description : '',
			});
		} else {
			let alertDescription = compiler({
				description: device ? device.description : '',
			});
			return alertDescription;
		}
	} else {
		return messages[`unknownEvent`];
	}
};

export const getLowCriticalLevel = (value, maxValue) => {
	if (value === undefined || value <= 0 || value <= maxValue * 0.35) return 'low';
	if (value <= maxValue * 0.65) return 'med';
	return 'high';
};

export const getHighCriticalLevel = (value, maxValue) => {
	if (value <= maxValue * 0.9) return 'high';
	if (value <= maxValue) return 'med';
	return 'low';
};

export const getBatteryLevel = (battery) => {
	if (battery === undefined || battery <= 0) return 'icon-battery-0';
	if (battery <= 25) return 'icon-battery-1';
	if (battery <= 50) return 'icon-battery-2';
	if (battery <= 75) return 'icon-battery-3';
	return 'icon-battery-4';
};

export const roundNextFive = (value) => {
	if (!value || value < 0) return 0;
	return value % 5 < 3 ? (value % 5 === 0 ? value : Math.floor(value / 5) * 5) : Math.ceil(value / 5) * 5;
};

export const distanceInMeters = (lat1, lon1, lat2, lon2) => {
	let radlat1 = (Math.PI * lat1) / 180;
	let radlat2 = (Math.PI * lat2) / 180;
	let theta = lon1 - lon2;
	let radtheta = (Math.PI * theta) / 180;
	let dist = Math.sin(radlat1) * Math.sin(radlat2) + Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
	if (dist > 1) {
		dist = 1;
	}
	dist = Math.acos(dist);
	dist = (dist * 180) / Math.PI;
	dist = dist * 60 * 1.1515;
	return dist * 1.609344 * 1000;
};

export const int18Catch = (msg) => {
	if (!msg) {
		return messages['noTranslation'];
	}
	return msg;
};

export const generateValidPassword = () => {
	let alphabetic = _.upperFirst(
		_.toLower(
			codeGenerator.generate({
				length: 5,
				count: 1,
				charset: codeGenerator.charset('alphabetic'),
			})[0]
		)
	);

	let numbers = codeGenerator.generate({
		length: 1,
		count: 1,
		charset: codeGenerator.charset('numbers'),
	})[0];
	return alphabetic + numbers;
};

export const IsValidJSONString = (str) => {
	try {
		JSON.parse(str);
	} catch (e) {
		return false;
	}
	return true;
};

export const partialFetch = (count, filter, fetch) => {
	const MaxFetchRows = 1000;
	const tasks = [];
	if (count <= MaxFetchRows) {
		tasks.push((callback) => fetch({ ...filter }, callback));
	} else {
		const fetchRemainder = count % MaxFetchRows > 0;
		const fetchCount = Math.floor(count / MaxFetchRows) + (fetchRemainder ? 1 : 0);
		filter.limit = MaxFetchRows;

		[...Array(fetchCount).keys()].forEach((index) => {
			const skip = index * MaxFetchRows;
			tasks.push((callback) => fetch({ ...filter, skip }, callback));
		});
	}
	return parallelLimit(tasks, 2);
};

export const cercha = (readings, volumes, reading) => {
	const index =
		reading >= readings[readings.length - 1] ? readings.length - 1 : readings.findIndex((e) => e > reading);
	return (
		((reading - readings[index]) * volumes[index - 1]) / (readings[index - 1] - readings[index]) +
		((reading - readings[index - 1]) * volumes[index]) / (readings[index] - readings[index - 1])
	);
};

export const interpolation = (readings, volumes, reading) => cercha(readings, volumes, reading);
export const GetVolumen = _.memoize(
	function (tank, voltageValue) {
		function SanitizeValue(minValue, maxValue, value) {
			if (value >= maxValue) return maxValue;
			if (value <= minValue) return minValue;
			return value;
		}
		const TankType = {
			Rectangular: 1,
			Cylindrical: 2,
			Irregular: 3,
			Percentage: 4,
			Volume: 5,
			Interpolation: 6,
		};
		var Volume = null;
		var L, h, v, R, W, H, D, minVoltageValue, maxVoltageValue, Formula;
		switch (tank.type) {
			case TankType.Rectangular:
				L = tank.length;
				W = tank.width;
				H = tank.height;
				minVoltageValue = tank.minValue;
				maxVoltageValue = tank.maxValue;
				h = (H * SanitizeValue(minVoltageValue, maxVoltageValue, voltageValue)) / maxVoltageValue;
				Volume = (L * W * h) / 1000.0;
				break;
			case TankType.Cylindrical:
				L = tank.length;
				D = tank.diameter;
				minVoltageValue = tank.minValue;
				maxVoltageValue = tank.maxValue;
				v = SanitizeValue(minVoltageValue, maxVoltageValue, voltageValue);
				R = D / 2;
				h = (D * v) / maxVoltageValue;
				Volume =
					(L * (Math.pow(R, 2) * Math.acos((R - h) / R) - (R - h) * Math.sqrt(2 * R * h - Math.pow(h, 2)))) /
					1000.0;
				break;
			case TankType.Irregular:
				Formula = tank.formula;
				minVoltageValue = tank.minValue;
				maxVoltageValue = tank.maxValue;
				var x = SanitizeValue(minVoltageValue, maxVoltageValue, voltageValue);
				parser.set('x', x);
				try {
					Volume = parser.evaluate(Formula);
					parser.clear();
				} catch (err) {
					Volume = 0;
				}
				break;
			case TankType.Percentage:
				var Percentage = 0;
				maxVoltageValue = tank.maxValue;
				switch (voltageValue) {
					case maxVoltageValue:
						Percentage = 1;
						break;
					case voltageValue >= 100:
						Percentage = 1;
						break;
					case voltageValue <= 0:
						Percentage = 0;
						break;
					default:
						Percentage = voltageValue / 100;
				}
				Volume = maxVoltageValue * Percentage;
				break;
			case TankType.Volume:
				Volume = voltageValue;
				break;
			case TankType.Interpolation:
				{
					if (!tank.formula || !IsValidJSONString(tank.formula)) return (Volume = 0);
					const configuration = JSON.parse(tank.formula);
					if (!configuration.samples && !Array.isArray(configuration.samples)) return (Volume = 0);
					const readings = configuration.samples.map((sample) => sample.reading);
					const volumes = configuration.samples.map((sample) => sample.volume);
					Volume = configuration.isLiter
						? interpolation(readings, volumes, voltageValue)
						: interpolation(readings, volumes, voltageValue) * LITER;
				}
				break;
			default:
				break;
		}

		if (Volume != null && !Number.isNaN(Volume)) {
			Volume = +Volume.toFixed(3);
		}
		return Volume;
	},
	(tank, value) => `${tank.id}${value}${tank.updatedOn}`
);

export const searchInString = (search, compare) => {
	if (compare && compare.length)
		return compare
			.toLowerCase()
			.normalize('NFD')
			.replace(/[\u0300-\u036f]/g, '')
			.includes(search.trim().toLowerCase());
	return false;
};

export const validatePoliceSiren = (events = [], siren = false) => {
	let digitalEvents = events.filter((event) => DIGITAL_INPUTS.includes(event.id));

	if (!digitalEvents.length) return siren;

	if (isSirenDigitalInput(digitalEvents, SIREN.SIREN_ON).length) {
		return true;
	} else if (isSirenDigitalInput(digitalEvents, SIREN.SIREN_OFF).length) {
		return false;
	}

	return siren;
};

const isSirenDigitalInput = (digitalEvents, digitalDescription) => {
	return digitalEvents.filter((event) => {
		if (!event.data) return false;

		let data = JSON.parse(event.data);

		return data.description === digitalDescription;
	});
};

export const checkRange = (dateRange) => {
	const startDate = moment(dateRange[0]);
	const endDate = moment(dateRange[1]);
	return endDate.diff(startDate, 'days') <= 31;
};

export const checkRangeByWeek = (dateRange) => {
	const startDate = moment(dateRange[0]);
	const endDate = moment(dateRange[1]);
	return endDate.diff(startDate, 'days') <= 7;
};

export const notAuthorized = (intl, username) => {
	message.error(intl.formatMessage(translate('youAreNotAuthorized'), { name: username }));
};

export const isVehicleMoving = ({ isOn, utcDate = null, speed, utcOffsetMinutes = 0 }) => {
	let today = moment.utc().utcOffset(utcOffsetMinutes);
	let lastReoport = moment.utc(utcDate).utcOffset(utcOffsetMinutes);
	const timeDif = today.diff(lastReoport, 'minutes') <= 3;
	if (isOn && timeDif) {
		return true;
	}
	return timeDif && speed >= 2;
};

export const validateDeviceHasPlate = (device) => device?.numberPlate;

export const disableCopyPaste = {
	onPaste: (event) => event.preventDefault(),
	onCopy: (event) => event.preventDefault(),
};

export const getDateRangeByDay = ({ startDate, endDate }) => {
	const range = moment.range(startDate, endDate);
	const rangeDays = Array.from(range.by('days'));
	if (rangeDays.length < 1 || rangeDays.length === 1)
		return [{ start: moment.utc(startDate), end: moment.utc(endDate) }];
	return rangeDays.map((day, index) => {
		if (index === 0) {
			return {
				start: moment.utc(startDate),
				end: moment.utc(day).endOf('days'),
			};
		} else if (index === rangeDays.length - 1) {
			return {
				start: moment.utc(day).startOf('days'),
				end: moment.utc(endDate),
			};
		}
		return {
			start: moment.utc(day).startOf('days'),
			end: moment.utc(day).endOf('days'),
		};
	});
};

export const formatAddess = ({ address, state, city, zipCode }) => {
	let result = address;
	if (state || city || zipCode) {
		if (state) {
			result = `${result}, ${state}`;
		}
		if (city) {
			result = `${result} ${city}`;
		}
		if (zipCode) {
			result = `${result} ${zipCode}`;
		}
	}

	return result;
};

export const slashToHyphenFormat = (dateVal) => {
	const slashFormat = dateVal.replace(/\//g, '-');
	const hyphenFormat = JSON.parse(slashFormat).date;
	return hyphenFormat;
};

export const disablePreviousDates = (currentDate, disableWeekends = false) => {
	const disablePastDays = currentDate < moment().startOf('day');
	if (disablePastDays) {
		return true;
	}

	if (disableWeekends) {
		const dayOfWeek = currentDate.day();
		return dayOfWeek === 0 || dayOfWeek === 6;
	}
	return false;
};

export const checkIfZeroValue = async (rule, value, intl) => {
	if (value === 0) throw new Error(intl.formatMessage(translate('valueIsZeroError')));
};

export const removeDuplicates = (array, arrayUniqueKey) => {
	let uniqueIds = [];
	const uniqueArray = array.filter((item) => {
		const currentItem = item[arrayUniqueKey];
		const isDuplicate = uniqueIds.includes(currentItem);
		if (!isDuplicate) {
			uniqueIds.push(currentItem);
			return true;
		}
		return false;
	});

	return uniqueArray;
};

export const infoWindowMetaData = (intl, device) => {
	const metaData = {
		title: intl.formatMessage(translate('position')),
		icon: icons.eventSvgToIcon({ id: 'position' }).url,
	};
	if (!device.event) {
		return metaData;
	}
	const { id: eventId, data: eventData } = device.event;
	if (eventData) {
		let data = JSON.parse(eventData);
		metaData.icon = icons.eventSvgToIcon({ id: `event${eventId}` }).url;

		switch (eventId) {
			case EVENT_ENUM.GEOFENCE_IN:
			case EVENT_ENUM.GEOFENCE_OUT:
				metaData.title = `${intl.formatMessage(translate(`event${eventId}`))} '${data.name}'`;
				break;
			case EVENT_ENUM.DIGITAL_INPUT_1_C:
			case EVENT_ENUM.DIGITAL_INPUT_1_D:
			case EVENT_ENUM.DIGITAL_INPUT_2_C:
			case EVENT_ENUM.DIGITAL_INPUT_2_D:
			case EVENT_ENUM.DIGITAL_INPUT_3_C:
			case EVENT_ENUM.DIGITAL_INPUT_3_D:
			case EVENT_ENUM.DIGITAL_INPUT_4_C:
			case EVENT_ENUM.DIGITAL_INPUT_4_D:
				metaData.title = data.description;
				metaData.icon = icons.eventSvgToIcon({ id: data.eventIconId }).url;
				break;
			case EVENT_ENUM.TANK_FILL:
				metaData.title = `${intl.formatMessage(translate(`event${eventId}`))}: ${unitSystem?.volume?.toString(
					data.filling,
					{ decimal: 2 }
				)}`;
				break;
			case EVENT_ENUM.PHOTO_REPORT:
				if (data.status === 2) {
					metaData.title = intl.formatMessage(translate(`event${eventId}`));
				} else {
					metaData.title = intl.formatMessage(translate('fotoInProcessTitle'));
				}
				break;
			case EVENT_ENUM.BEHAVIOR_MAX_SPEED_LIMIT:
				metaData.title = `${intl.formatMessage(translate('eventOverSpeedOnGeofence'), {
					geofence: data.name,
				})} ${unitSystem?.speed?.toString(data.maxSpeed, {
					decimal: 0,
				})}`;
				break;
			case EVENT_ENUM.SPEEDING:
				metaData.title = `${intl.formatMessage(translate('eventMaxSpeed'))} ${unitSystem?.speed?.toString(
					data.speedLimit,
					{ decimal: 0 }
				)}`;
				break;
			default: {
				if (messages.hasOwnProperty(`event${eventId}`)) {
					metaData.title = intl.formatMessage(translate(`event${eventId}`));
				} else {
					metaData.title = intl.formatMessage(translate('unknownEvent'));
				}
				break;
			}
		}
	} else {
		if (messages.hasOwnProperty(`event${eventId}`)) {
			metaData.title = intl.formatMessage(translate(`event${eventId}`));
			metaData.icon = icons.eventSvgToIcon({ id: `event${eventId}` }).url;
		} else {
			metaData.title = intl.formatMessage(translate('unknownEvent'));
		}
	}
	return metaData;
};

export const getValueWithCurrency = (value, currency = '$') => {
	return `${currency}${value.toFixed(2)}`;
};

export const initExtraConfig = ({ extraConfig, eventId }) => {
	let extraOptions = extraConfig;
	if (extraOptions) {
		switch (eventId) {
			case EVENT_ENUM.SPEEDING:
				if (extraOptions.maxSpeed > 0) {
					extraOptions = {
						...extraOptions,
						maxSpeed: unitSystem.speed.format(extraOptions.maxSpeed, {
							decimal: 0,
						}),
						enableAlert: extraOptions.enableAlert || false,
					};
				}
				break;
			case EVENT_ENUM.IRREGULAR_FUEL_CONSUMPTION:
				extraOptions = {
					...extraOptions,
					minConsumptionVolume: unitSystem.volume.format(extraOptions.minConsumptionVolume, {
						decimal: 3,
					}),
				};
				break;
			case EVENT_ENUM.VEHICLE_IDLE:
				extraOptions = {
					time: moment(extraOptions.time, 'mm'),
					rpmStartRange: extraOptions?.rpmRange?.initial || extraOptions?.rpmStartRange,
					rpmFinalRange: extraOptions?.rpmRange?.final || extraOptions?.rpmFinalRange,
				};
				break;
			case EVENT_ENUM.TANK_FILL: {
				extraOptions = {
					...extraOptions,
					minFillingVolume: unitSystem.volume.format(extraOptions.minFillingVolume, {
						decimal: 3,
					}),
				};
				break;
			}
			case EVENT_ENUM.OUT_OF_SCHEDULE: {
				const { schedule, otherProps, ...rest } = extraOptions;
				extraOptions = { schedule: schedule ?? [scheduleTemplate] };

				if (otherProps) {
					extraOptions = {
						...extraOptions,
						otherProps,
					};
				} else {
					extraOptions = {
						...extraOptions,
						otherProps: rest,
					};
				}
				break;
			}
			default:
				break;
		}
	} else {
		switch (eventId) {
			case EVENT_ENUM.SPEEDING: {
				extraOptions = {
					maxSpeed: unitSystem.speed.format(MIN_SPEED_DEFAULT, {
						decimal: 0,
					}),
				};
				break;
			}
			case EVENT_ENUM.IRREGULAR_FUEL_CONSUMPTION: {
				extraOptions = {
					...extraOptions,
					minConsumptionVolume: 0,
				};
				break;
			}
			case EVENT_ENUM.VEHICLE_IDLE:
				extraOptions = {
					time: moment('10', 'mm'),
				};
				break;
			case EVENT_ENUM.TANK_FILL: {
				extraOptions = {
					...extraOptions,
					minFillingVolume: 0,
				};
				break;
			}
			case EVENT_ENUM.OUT_OF_SCHEDULE: {
				extraOptions = { schedule: [scheduleTemplate] };
				break;
			}
			default:
				break;
		}
	}
	return extraOptions;
};
