import { FETCH_CLIENT_SCHELUDED_DEACTIVATION, UPDATE_SHOW_MODAL_DEACTIVATION } from './actionTypes';
import ScheludedDeactivationService from './scheludedDeactivation.service';

export const checkClientDisableDate = () => async (dispatch) => {
	try {
		const data = await ScheludedDeactivationService.getClientDisableDate();
		dispatch({ type: FETCH_CLIENT_SCHELUDED_DEACTIVATION, deactivationInfo: data.success });
	} catch (error) {
		dispatch({ type: FETCH_CLIENT_SCHELUDED_DEACTIVATION, deactivationInfo: {} });
	}
};

export const confirmDeactivationMessage = (disableDate) => async (dispatch) => {
	try {
		const data = await ScheludedDeactivationService.saveConfirmDeactivationMessage(disableDate);
		dispatch({ type: UPDATE_SHOW_MODAL_DEACTIVATION, showModal: data.success });
	} catch (error) {
		return Promise.reject(error);
	}
};
